import { InjectionKey } from 'vue'
import { SessionListItemViewModel } from '@/components/SessionListItem/SessionListItem.interfaces'

export interface SessionRegistryState {
  sessions: SessionListItemViewModel[]
  webinars: SessionListItemViewModel[]
  attendeeIsRegistered: boolean
  isLoading: boolean
  isReady: boolean
  isLSAT: boolean
  isEBar: boolean
  isUSBar: boolean
  error?: string
  selectedSession?: SessionListItemViewModel
  isRegistering: boolean
  coachName: string
}

export interface SessionRegistryActions {
  loadSessions(): Promise<void>
  registerToSession(): Promise<void>
  selectSession(session: SessionListItemViewModel): void
  unSelectSession(): void
}

export interface SessionRegistryStore {
  sessionState: SessionRegistryState
  sessionActions: SessionRegistryActions
}

export enum EbarSessionSortCriteria {
  Sessions,
  Time,
  Dates,
  Title,
  Availability
}

export enum WebinarSortCriteria {
  Title,
  Date,
  Time
}

export enum SessionSortCriteria {
  Date,
  Time,
  Title,
  Instructor,
  Availability
}

export const SessionRegistryStoreKey: InjectionKey<SessionRegistryStore> = Symbol('SessionRegistryStore')
